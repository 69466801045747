
/**
 * @name: 仓储管理-盘点记录
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 仓储管理-盘点记录
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {deepCopy, exportFile} from "@/utils/common";
import {exportCheckRecordApi, getCheckRecordPageApi} from "@/apis/storage/checkList";
import config from "@/config";
import {ICheckRecordPage} from "@/apis/storage/checkList/types";

@Component({})
export default class storageCheckList extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICheckRecordPage = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "盘点时间",
        prop: "takeStockTime",
        align: "center",
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: 'center',
      },
      {
        label: "档口",
        prop: "gateIdList",
        align: "center",
        search: true,
        searchMultiple: true,
        type: 'select',
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        hide: true
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        search: true
      },
      {
        label: "商品分类",
        prop: "sortIdList",
        align: "center",
        searchMultiple: true,
        search: true,
        type: 'select',
        dicUrl: "/api/admin/product/getSortList?status=1",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
        hide: true
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
      },
      {
        label: "盘点前库存",
        prop: "beforeStockQty",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点后库存",
        prop: "afterStockQty",
        align: 'center',
        // slot:true
      },
      {
        label: "误差数量",
        prop: "errorQty",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点价",
        prop: "stocktakingPrice",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点结果",
        prop: "totalPrice",
        align: 'center',
        // slot:true
      },
      {
        label: "盘点说明",
        prop: "illustrate",
        align: 'center',
        overHidden:true
      },
      {
        label: "相关图片",
        prop: 'stocktakingPic',
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
      },
      {
        label: "盘点时间",
        prop: "takeStockTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
        hide: true
      },
    ]
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ICheckRecordPage = deepCopy(this.queryParam);
    if (this.queryParam.takeStockTime && this.queryParam.takeStockTime.length) {
      form.startTime = this.queryParam.takeStockTime[0]
      form.endTime = this.queryParam.takeStockTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.takeStockTime
    form.sortIdList = form.sortIdList || []
    form.gateIdList = form.gateIdList || []
    exportCheckRecordApi(form).then(e => {
      exportFile(e, '盘点记录.xlsx')
    })
  }

  getList() {
    const form: ICheckRecordPage = deepCopy(this.queryParam);
    if (this.queryParam.takeStockTime && this.queryParam.takeStockTime.length) {
      form.startTime = this.queryParam.takeStockTime[0]
      form.endTime = this.queryParam.takeStockTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.takeStockTime
    form.sortIdList = form.sortIdList?.toString()
    form.gateIdList = form.gateIdList?.toString()
    getCheckRecordPageApi(form).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  created() {
    this.getList()
  }
}
