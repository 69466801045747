/**
 * @name: 仓储管理-盘点记录接口文件
 * @author:
 * @date: 2023-08-04 17:46
 * @description： 仓储管理-盘点记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {ICheckRecordPage} from "@/apis/storage/checkList/types";


/**
 * 分页查询
 * @returns
 */
export const getCheckRecordPageApi = (param:ICheckRecordPage) => get("/admin/stocktakingRecord/getCheckRecordPage",param)

/**
 * 导出入库记录数据
 * @param params 查询参数
 * @returns
 */
export const exportCheckRecordApi = (params: ICheckRecordPage) => postJ("/admin/stocktakingRecord/exportCheckRecord", params,"blob")
